import React, { Component } from 'react'
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import {APIURL, PORT} from '../../config/config'
import { connect } from 'react-redux'
import { save } from 'save-file';
import { AlertWarning } from '../Alert/Alert'


class TradingReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),

      sum_real_sell_price: 0,
      sum_car_cost: 0,
      sum_income_other: 0,
      sum_expense_total: 0,
      sum_gross_profit: 0,
      lists: [],

      sum_vat_buy: 0,
      sum_total_car_cost: 0,

      //
      sum_car_cost_commissions: 0,
      sum_cost_phra: 0,
      sum_cost_vat_and_property_tax : 0,
      sum_cost_operation: 0,
      sum_cost_service: 0,
      sum_cost_transport: 0,
      sum_cost_porobor_lan: 0,
      sum_cost_bangkhab_phasi_lan: 0,
      sum_cost_bangkhab_xon_lan: 0,
      sum_cost_check_ton: 0,
      sum_cost_after_sales_service: 0,
      sum_finance_commission: 0,
      sum_vat_commission: 0,
      sum_car_external_commission: 0,
      sum_sales_commission: 0,
      sum_additional_commission: 0,
      sum_advertising_cost: 0,
      sum_delivery_fee: 0,
      sum_promotion_fee: 0,
      sum_finance_fee: 0,
      sum_transfer_fee: 0,
      sum_car_cost_repair: 0,
      sum_car_cost_other: 0,
      sum_car_cost_other2: 0,
      sum_car_cost_tax: 0,
      sum_car_cost_act: 0,
      sum_amount_of_debt: 0,
    }
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadSellData = this.loadSellData.bind(this)
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSellData() {
    // const {date_start, date_end} = this.state

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    // const data = {
    //   start_date,
    //   end_date
    // } buy_car_from
    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/report/trading/'+start_date+'/'+end_date)
      .then(res => {

        let sum_real_sell_price = 0
        let sum_car_cost = 0
        let sum_income_other = 0
        let sum_expense_total = 0
        let sum_gross_profit = 0

        let sum_vat_buy = 0
        let sum_total_car_cost = 0

        let sum_car_cost_commissions = 0
        let sum_cost_phra = 0
        let sum_cost_vat_and_property_tax = 0
        let sum_cost_operation = 0
        let sum_cost_service = 0
        let sum_cost_transport = 0
        let sum_cost_porobor_lan = 0
        let sum_cost_bangkhab_phasi_lan = 0
        let sum_cost_bangkhab_xon_lan = 0
        let sum_cost_check_ton = 0
        let sum_cost_after_sales_service = 0
        let sum_finance_commission = 0
        let sum_vat_commission = 0
        let sum_car_external_commission = 0
        let sum_sales_commission = 0
        let sum_additional_commission = 0
        let sum_advertising_cost = 0
        let sum_delivery_fee = 0
        let sum_promotion_fee = 0
        let sum_finance_fee = 0
        let sum_transfer_fee = 0
        let sum_car_cost_repair = 0
        let sum_car_cost_other = 0
        let sum_car_cost_other2 = 0
        let sum_car_cost_tax = 0
        let sum_car_cost_act = 0
        let sum_amount_of_debt = 0

        if(res.data.length > 0) {

          sum_vat_buy = res.data.reduce((acc, next) => acc + next.vat_buy, 0)
          sum_real_sell_price = res.data.reduce((acc, next) => acc + next.real_sell_price, 0)
          sum_car_cost = res.data.reduce((acc, next) => acc + next.car_cost, 0)
          sum_total_car_cost = sum_car_cost + sum_vat_buy

          sum_expense_total = res.data.reduce((acc, next) => {
            // return acc + next.expense_total;
            // รายจ่ายการขาย
            /*let expense_total = Number(next.car_cost_commissions) + Number(next.car_cost_repair) + Number(next.car_cost_tax) + Number(next.car_cost_act) + Number(next.car_cost_expenses_other) +
                                Number(next.car_external_commission) + Number(next.sales_commission) + Number(next.additional_commission) + Number(next.advertising_cost) + Number(next.delivery_fee) +
                                Number(next.promotion_fee) + Number(next.finance_fee) + Number(next.transfer_fee)*/
            let expense_total = Number(next.car_cost_commissions) + Number(next.car_cost_repair) + Number(next.car_cost_tax) + Number(next.car_cost_act) + Number(next.car_cost_expenses_other) +
                                Number(next.car_external_commission) + Number(next.sales_commission) + Number(next.additional_commission) + Number(next.advertising_cost) + Number(next.delivery_fee) +
                                Number(next.promotion_fee) + Number(next.finance_fee) + Number(next.transfer_fee) +
                                Number(next.cost_operation) + Number(next.cost_service) + Number(next.cost_transport) +
                                Number(next.cost_porobor_lan) + Number(next.cost_bangkhab_phasi_lan) + Number(next.cost_bangkhab_xon_lan) + Number(next.cost_check_ton) +
                                Number(next.cost_after_sales_service) + Number(next.cost_phra) + Number(next.cost_vat_and_property_tax)
            return acc + expense_total
          }, 0)

          //รวมรายรับจากการขาย
          const vat_invoice = res.data.reduce((acc, next) => {
            let tmp = next.tax_invoice_vat > 0 ? next.tax_invoice_vat : next.vat_sell
            return acc + tmp
          }, 0) //VAT ยอดจัด

          const commission_amount = res.data.reduce((acc, next) => {
            let tmp = next.tax_commission_amount > 0 ? next.tax_commission_amount : next.finance_commission
            return acc + tmp
          }, 0)  //ค่าคอม

          const vat_commission = res.data.reduce((acc, next) => {
            let tmp = next.tax_commission_vat > 0 ? next.tax_commission_vat : next.vat_commission
            return acc + tmp
          }, 0)  //VAT ค่าคอม

          sum_income_other = vat_invoice + commission_amount + vat_commission

          /*sum_gross_profit = res.data.reduce((acc, car) => {
            // return acc + next.gross_profit
            const car_cost = car.car_cost // คอมฯซื้อเข้า
            const vat_buy = car.vat_buy // คอมฯซื้อเข้า
            const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
            const car_cost_repair = car.car_cost_repair
            const car_cost_other = car.car_cost_other
            const car_cost_expenses_other = car.car_cost_expenses_other + car_cost_other
            const car_cost_tax = car.car_cost_tax
            const car_cost_act = car.car_cost_act

            const cost_operation = car.cost_operation
            const cost_service = car.cost_service
            const cost_transport = car.cost_transport
            const cost_porobor_lan = car.cost_porobor_lan
            const cost_bangkhab_phasi_lan = car.cost_bangkhab_phasi_lan

            const cost_bangkhab_xon_lan = car.cost_bangkhab_xon_lan
            const cost_check_ton = car.cost_check_ton
            const car_external_commission = car.car_external_commission

            const sales_commission = car.sales_commission
            const additional_commission = car.additional_commission
            const advertising_cost = car.advertising_cost
            const delivery_fee = car.delivery_fee
            const finance_fee = car.finance_fee

            const promotion_fee = car.promotion_fee
            const transfer_fee = car.transfer_fee
            const cost_after_sales_service = car.cost_after_sales_service
            const cost_phra = car.cost_phra
            const cost_vat_and_property_tax = car.cost_vat_and_property_tax

            const profit_before_sell = car.real_sell_price - (car_cost + vat_buy + car_cost_commissions + car_cost_repair  + car_cost_expenses_other + car_cost_tax + car_cost_act)
            const profit_after_sell = (sum_income_other) - (cost_operation + cost_service + cost_transport + cost_porobor_lan + cost_bangkhab_phasi_lan +
                                                            cost_bangkhab_xon_lan + cost_check_ton + car_external_commission + sales_commission +
                                                            additional_commission + advertising_cost + delivery_fee + finance_fee + promotion_fee +
                                                            promotion_fee + transfer_fee + cost_after_sales_service + cost_phra + cost_vat_and_property_tax)
            const gross_profit = profit_before_sell + profit_after_sell
            return acc + gross_profit

          }, 0)*/

          //
          sum_car_cost_commissions = res.data.reduce((acc, next) => acc + next.car_cost_commissions, 0)
          sum_car_cost_repair =  res.data.reduce((acc, next) => acc + next.car_cost_repair, 0)
          sum_car_cost_tax = res.data.reduce((acc, next) => acc + next.car_cost_tax, 0)
          sum_car_cost_act = res.data.reduce((acc, next) => acc + next.car_cost_act, 0)
          sum_car_cost_other =  res.data.reduce((acc, next) => acc + next.car_cost_expenses_other, 0)
          sum_car_cost_other2 =  res.data.reduce((acc, next) => acc + next.car_cost_other, 0)
          sum_car_cost_other = sum_car_cost_other+sum_car_cost_other2

          sum_cost_operation = res.data.reduce((acc, next) => acc + next.cost_operation, 0) //ค่าดำเนินการ
          sum_cost_service = res.data.reduce((acc, next) => acc + next.cost_service, 0)//ค่าบริการ
          sum_cost_transport = res.data.reduce((acc, next) => acc + next.cost_transport, 0)//ค่าขนย้าย
          sum_cost_porobor_lan = res.data.reduce((acc, next) => acc + next.cost_porobor_lan, 0)//ค่า พรบ. จากลาน
          sum_cost_bangkhab_phasi_lan = res.data.reduce((acc, next) => acc + next.cost_bangkhab_phasi_lan, 0)//ค่าบังคับต่อภาษี จากลาน
          sum_cost_bangkhab_xon_lan = res.data.reduce((acc, next) => acc + next.cost_bangkhab_xon_lan, 0)//ค่าบังคับโอนเข้าเต็นท์ จากลาน
          sum_cost_check_ton = res.data.reduce((acc, next) => acc + next.cost_check_ton, 0)//ค่าเช็คต้น

          sum_car_external_commission = res.data.reduce((acc, next) => acc + next.car_external_commission, 0)
          sum_sales_commission = res.data.reduce((acc, next) => acc + next.sales_commission, 0)
          sum_additional_commission = res.data.reduce((acc, next) => acc + next.additional_commission, 0)
          sum_advertising_cost = res.data.reduce((acc, next) => acc + next.advertising_cost, 0)
          sum_delivery_fee = res.data.reduce((acc, next) => acc + next.delivery_fee, 0)
          sum_promotion_fee = res.data.reduce((acc, next) => acc + next.promotion_fee, 0)
          // รายจ่ายการขาย 2
          sum_finance_fee = res.data.reduce((acc, next) => acc + next.finance_fee, 0) // ค่าธรรมเนียมไฟแนนท์
          sum_transfer_fee = res.data.reduce((acc, next) => acc + next.transfer_fee, 0) // ค่าโอนรถ

          sum_cost_after_sales_service = res.data.reduce((acc, next) => acc + next.cost_after_sales_service, 0)//บริการหลังการขาย

          sum_cost_phra = res.data.reduce((acc, next) => acc + next.cost_phra, 0)
          sum_cost_vat_and_property_tax = res.data.reduce((acc, next) => acc + next.cost_vat_and_property_tax, 0)

          sum_amount_of_debt = res.data.reduce((acc, next) => acc + next.amount_of_debt, 0)

          sum_gross_profit = sum_real_sell_price - (sum_car_cost + sum_vat_buy + sum_car_cost_commissions + sum_car_cost_repair + sum_car_cost_other+ sum_car_cost_tax + sum_car_cost_act) +
                                            (sum_income_other) -
                                            (sum_cost_operation + sum_cost_service + sum_cost_transport + sum_cost_porobor_lan + sum_cost_bangkhab_phasi_lan + sum_cost_bangkhab_xon_lan +
                                            sum_cost_check_ton + sum_car_external_commission + sum_sales_commission + sum_additional_commission + sum_advertising_cost + sum_delivery_fee +
                                            sum_promotion_fee + sum_finance_fee + sum_transfer_fee + sum_cost_after_sales_service + sum_cost_phra + sum_cost_vat_and_property_tax)-(sum_amount_of_debt)

        }

        this.setState({
          lists: res.data,
          sum_real_sell_price: sum_real_sell_price,
          sum_car_cost: sum_car_cost,
          sum_income_other: sum_income_other,
          sum_expense_total: sum_expense_total,
          sum_gross_profit: sum_gross_profit,
          sum_vat_buy: sum_vat_buy,
          sum_total_car_cost: sum_total_car_cost,

          sum_car_cost_commissions: sum_car_cost_commissions ,
          sum_cost_phra: sum_cost_phra ,
          sum_cost_vat_and_property_tax: sum_cost_vat_and_property_tax ,
          sum_cost_operation: sum_cost_operation ,
          sum_cost_service: sum_cost_service ,
          sum_cost_transport: sum_cost_transport ,
          sum_cost_porobor_lan: sum_cost_porobor_lan ,
          sum_cost_bangkhab_phasi_lan: sum_cost_bangkhab_phasi_lan ,
          sum_cost_bangkhab_xon_lan: sum_cost_bangkhab_xon_lan ,
          sum_cost_check_ton: sum_cost_check_ton ,
          sum_cost_after_sales_service: sum_cost_after_sales_service ,
          sum_finance_commission: sum_finance_commission ,
          sum_vat_commission: sum_vat_commission ,
          sum_car_external_commission: sum_car_external_commission ,
          sum_sales_commission: sum_sales_commission ,
          sum_additional_commission: sum_additional_commission ,
          sum_advertising_cost: sum_advertising_cost ,
          sum_delivery_fee: sum_delivery_fee ,
          sum_promotion_fee: sum_promotion_fee ,
          sum_finance_fee: sum_finance_fee ,
          sum_transfer_fee: sum_transfer_fee ,
          sum_car_cost_repair: sum_car_cost_repair ,
          sum_car_cost_other: sum_car_cost_other ,
          sum_car_cost_other2: sum_car_cost_other2 ,
          sum_car_cost_tax: sum_car_cost_tax ,
          sum_car_cost_act: sum_car_cost_act ,
          sum_amount_of_debt: sum_amount_of_debt ,
          isLoading: false
        })
      }).catch(error => {
        console.log('error:', error)
       // this.setState({lists: []})
    })
  }

  exportSellData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: false
    })

    axios.get(APIURL + '/report/tradingexport/'+start_date+'/'+end_date)
      .then( async res => {
        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'รายงานการซื้อขาย_export_'+currentDatetime+'.xlsx'
        this.setState({
          isLoading: false
        })
        await save(excelBuffer, fileName)

      }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  render () {
    const {
      lists,
      sum_real_sell_price,
      sum_car_cost,
      sum_income_other,
      sum_expense_total,
      sum_gross_profit,
      sum_total_car_cost,
      sum_amount_of_debt
    } = this.state

    const emptyBody = <tr><td colSpan={17} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {
      const car_sell_date = moment(car.car_sell_date).locale('th').format('LL')
      const car_date_in = moment(car.car_date_in).locale('th').format('LL')
      const buy_car_from = car.buy_car_from
      const customer_fullname = car.customer_name + ' ' + car.customer_lastname
      const car_sell_type_name = car.car_sell_type_name
      const bank_name = car.bank_name
      const car_cost = car.car_cost + car.vat_buy// ต้นทุนซื้อเข้า

      const vat_invoice = car.vat_sell > 0 ? car.vat_sell : car.tax_invoice_vat //VAT ยอดจัด
      const finance_commission = car.tax_commission_amount > 0 ? car.tax_commission_amount : car.finance_commission  //ค่าคอม
      const vat_commission = car.tax_commission_vat > 0 ? car.tax_commission_vat : car.vat_commission  //VAT ค่าคอม
      // const tax_commission_amount = car.tax_commission_amount > 0 ? car.tax_commission_amount : car.finance_commission  //ค่าคอม
      // const tax_commission_vat = car.tax_commission_vat > 0 ? car.tax_commission_vat : car.vat_commission  //VAT ค่าคอม

      const real_sell_price = car.real_sell_price
      const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
      const car_cost_repair = car.car_cost_repair

      const car_cost_tax = car.car_cost_tax // ค่าภาษีรถยนต์
      const car_cost_act = car.car_cost_act // ค่าใช้จ่าย พ.ร.บ./ค่าภาษีรถยนต์
      const car_cost_other = car.car_cost_other
      const car_cost_expenses_other = car.car_cost_expenses_other + car_cost_other

      // รายรับ
      const income_other = vat_invoice + finance_commission + vat_commission

      // รวมค่าใช้จ่าย/ค่าซ่อม
      /*const expense_total = Number(car.car_cost_commissions) + Number(car.car_cost_repair) + Number(car.car_cost_tax) + Number(car.car_cost_act) +
          Number(car.car_cost_expenses_other) + Number(car.car_external_commission) + Number(car.sales_commission) +
          Number(car.additional_commission) + Number(car.advertising_cost) + Number(car.delivery_fee) + Number(car.promotion_fee) +
          Number(car.finance_fee)+Number(car.transfer_fee)*/
      const expense_before_total = car_cost_tax + car_cost_act + car_cost_commissions + car.cost_operation + car.cost_service +
                                   car.cost_transport + car.cost_porobor_lan + car.cost_bangkhab_phasi_lan +
                                   car.cost_bangkhab_xon_lan + car.cost_check_ton + car_cost_repair + car_cost_expenses_other

      const expense_after_total =  car.car_external_commission + car.sales_commission + car.additional_commission + car.advertising_cost +
                                   car.delivery_fee + car.promotion_fee + car.finance_fee + car.transfer_fee + car.cost_after_sales_service + car.cost_phra + car.cost_vat_and_property_tax

      const expense_total = expense_before_total + expense_after_total

      // const profit_before_sell = car.real_sell_price - car_cost + (car_cost_commissions + car_cost_repair + car_cost_expenses_other + car_cost_tax + car_cost_act)
      const profit_before_sell = car.real_sell_price - (car_cost + expense_before_total)
      const profit_after_sell = (income_other) - expense_after_total

      const amount_of_debt = Number(car.amount_of_debt)
      //console.log(typeof amount_of_debt, amount_of_debt)

      let gross_profit = profit_before_sell + profit_after_sell

      if (PORT !==9048) {
        gross_profit = gross_profit - amount_of_debt
      }

      let car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.car_license_plate_new : car.car_license_plate_old
      const province_name = car.car_license_plate_new !== '' ||  car.car_license_plate_new === 999 &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.province_name_new : car.province_name_old


      let customer_mobile = car.customer_mobile
      let customer_address = car.customer_address
      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center">{index+1}</td>
          <td className="text-nowrap" style={{width: 220}}>{car.car_name}</td>
          <td className="text-center text-nowrap" style={{width: 120}} >{car_license_plate} <br/> {province_name}</td>
          <td className="text-center text-nowrap" style={{width: 170}} >{car_date_in}</td>
          <td className="text-nowrap" style={{width: 120}} >{buy_car_from}</td>
          <td className="text-center text-nowrap" style={{width: 170}} >{car_sell_date}</td>
          <td className="text-nowrap" style={{width: 300}} >{customer_fullname}</td>
          <td className="text-nowrap" style={{width: 300}} >{customer_mobile}</td>
          <td className="text-nowrap" style={{width: 300}} >{customer_address}</td>
          <td className="text-nowrap" style={{width: 120}} >{car_sell_type_name}</td>
          <td className="text-nowrap" style={{width: 120}} >{bank_name}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{real_sell_price.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{car_cost.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{income_other.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{expense_total.toLocaleString()}</td>
          <td className="text-nowrap text-danger" style={{width: 140}} align="right" >-{amount_of_debt.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{gross_profit.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 120}} >{car.employee_name}</td>
          {/*<td style={{width: 60}} className="text-center">{car.employee_name}</td>*/}
          {/*<td className="text-right">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>*/}
          {/*<td className="text-right">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>*/}

        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    const tableSum = <tr>
                  <td className="text-right font-weight-bold" colSpan={11}>รวม</td>
                  <td className="text-right font-weight-bold">{sum_real_sell_price.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_total_car_cost.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_income_other.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_expense_total.toLocaleString()}</td>
                  <td className="text-right font-weight-bold text-danger">-{sum_amount_of_debt.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_gross_profit.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{''}</td>
                </tr>

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="row">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">รายงานการซื้อขาย</strong>
                </div>
                <div className="card-block">

                  <div className="row mt-1">
                    <div className="col-1 mt-1 text-right">
                      จาก
                    </div>

                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="mt-1 text-right">
                      ถึง
                    </div>

                    <div className="col-2 ml-3">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSellData()} > ตกลง </button>
                    </div>

                    <div className="d-flex">
                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSellData()} > Excel </button>
                    </div>

                    <div className="col-4 text-right pt-2">
                      {/*<h5>
                        รวมยอดขาย&nbsp;
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={this.state.sum_expense}/>
                        &nbsp;บาท
                      </h5>*/}
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <table className="" border={1} style={{border: 1, minHeight: 250}} >
                        <thead>
                          <tr>
                            <th  style={styles.headerTable} >ลำดับ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 220}}}  >ชื่อรถ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ทะเบียนรถ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >วันที่ซื้อรถเข้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ซื้อรถจาก</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >วันที่ขาย</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ลูกค้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >เบอร์โทรลูกค้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ที่อยู่ลูกค้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ประเภทการขาย</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ไฟแนนซ์</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >ราคาขายจริง</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >ราคาซื้อเข้า <br/> รวม VAT</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >รวมรายรับ <br/> อื่นๆของรถ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >รวมค่าใช้จ่าย <br/> และค่าซ่อม</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >ตั้งหนี้</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >กำไรเบื้องต้น</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ขายโดย</th>
                          </tr>
                        </thead>
                        <tbody>
                         {tableRows}
                         {tableSum}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}


const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(TradingReport);
